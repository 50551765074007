<template>
  <div>
    <e-charts
      ref="myChart"
      autoresize
      :manual-update="true"
      theme="theme-color"
      auto-resize
    />
    <b-button
      class="mt-1"
      variant="outline-secondary"
      size="sm"
      @click="downloadImage"
    >
      <feather-icon
        class="text-secondary"
        icon="DownloadIcon"
      />
      &nbsp;
      Descargar imagen
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
    BButton,
  },
  props: {
    globalBenchmarks: {
      type: Object,
      default: () => {},
    },
    networkBenchmarks: {
      type: Object,
      default: () => {},
    },
    networkName: {
      type: String,
      default: 'Red',
    },
  },
  computed: {
    chartOptions() {
      if (!this.globalBenchmarks) {
        return null
      }

      // ************ Global Benchmarks **************
      // Object to array
      const globalBenchmarksArray = []
      Object.keys(this.globalBenchmarks).forEach(k => {
        globalBenchmarksArray.push(this.globalBenchmarks[k])
      })
      // Get common x axis
      const xAxisData = globalBenchmarksArray.map(m => m.year)
      // Get y axis for global benchmarks
      const yGlobalBenchmarkData = []
      xAxisData.forEach(year => {
        const item = globalBenchmarksArray.find(gb => gb.year === year)
        if (item && item.value.computedValue) {
          yGlobalBenchmarkData.push(item.value.computedValue)
        } else {
          yGlobalBenchmarkData.push(null)
        }
      })

      // ************ Global Benchmarks **************
      // Object to array
      const networkBenchmarksArray = []
      Object.keys(this.networkBenchmarks).forEach(k => {
        networkBenchmarksArray.push(this.networkBenchmarks[k])
      })
      // Get y axis for network benchmarks
      const yNetworkBenchmarkData = []
      xAxisData.forEach(year => {
        const item = networkBenchmarksArray.find(gb => gb.year === year)
        if (item && item.value.computedValue) {
          yNetworkBenchmarkData.push(item.value.computedValue)
        } else {
          yNetworkBenchmarkData.push(null)
        }
      })

      // Return chart options
      return {
        backgroundColor: '#FFF7EB',
        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: [
                  '#eeeeee',
                ],
              },
            },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            name: this.networkName,
            type: 'bar',
            data: yNetworkBenchmarkData,
            barMaxWidth: '20%',
            barMinWidth: '10px',
            itemStyle: {
              barBorderRadius: [3, 3, 0, 0],
            },
          },
          {
            name: 'Benchmark General',
            type: 'line',
            data: yGlobalBenchmarkData,
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          left: 0,
        },
      }
    },
  },
  watch: {
    chartOptions(newValue) {
      if (newValue) {
        this.$refs.myChart.mergeOptions(newValue, true)
      }
    },
  },
  methods: {
    downloadImage() {
      const canvas = document.getElementsByTagName('canvas')
      if (canvas && canvas.length > 0) {
        // create label
        const tempA = document.createElement('a')
        // Set download name
        tempA.download = 'indicador.png'
        // Set address and file type
        tempA.href = canvas[0].toDataURL('image/png')
        document.body.appendChild(tempA)
        // Trigger download event
        tempA.click()
        // Remove Tag
        tempA.remove()
      }
    },
  },
}
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
