<template>
  <div>
    <custom-app-breadcrumb
      :page-title="title"
      :breadcrumb="breadcrumb"
    />
    <b-row>
      <b-col md="3">
        <indicators-filter
          :indicators="indicators"
          @select-indicator="onSelectIndicator"
        />
      </b-col>
      <b-col md="9">
        <indicator-card
          v-if="selectedIndicator"
          :network-id="networkId"
          :indicator="selectedIndicator"
          :network-name="networkName"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import CustomAppBreadcrumb from '@/layouts/components/CustomAppBreadcrumb.vue'
import IndicatorsFilter from '@/components/indicators/IndicatorsFilter.vue'
import IndicatorCard from './IndicatorCard.vue'

import indicators from '../../services/indicators'

export default {
  components: {
    BRow,
    BCol,
    CustomAppBreadcrumb,
    IndicatorsFilter,
    IndicatorCard,
  },
  data() {
    return {
      indicators: [],
      selectedIndicator: null,
    }
  },
  computed: {
    title() {
      return `Benchmarks ${this.networkName}`
    },
    breadcrumb() {
      return [
        {
          text: 'Benchmarks de Red',
          active: true,
        },
        {
          text: this.networkName,
          active: true,
        },
      ]
    },
    userInfo() {
      return this.$store.state.services.userInfo
    },
    networkName() {
      return (this.userInfo) ? this.userInfo.network.name : ''
    },
    networkId() {
      return (this.userInfo) ? this.userInfo.network.id : ''
    },
  },
  mounted() {
    this.$rtdbBind('indicators', indicators.bindAll())
  },
  methods: {
    onSelectIndicator(value) {
      this.selectedIndicator = value
    },
  },
}
</script>
