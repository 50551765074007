<template>
  <b-card :title="indicator.description">
    <div class="text-center">
      <b-spinner
        v-show="loading"
        style="width: 5rem; height: 5rem;"
        class="mt-4 mb-3"
      />
    </div>
    <indicator-graph
      ref="indicatorChart"
      class="mt-4"
      :global-benchmarks="benchmarks.globalBenchmarks"
      :network-benchmarks="benchmarks.networkBenchmarks"
      :network-name="networkName"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IndicatorGraph from './IndicatorGraph.vue'
import benchmarks from '../../services/benchmarks'

export default {
  components: {
    BCard,
    IndicatorGraph,
    BSpinner,
  },
  props: {
    networkId: {
      type: String,
      default: '',
    },
    networkName: {
      type: String,
      default: '',
    },
    indicator: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      meassurements: null,
      benchmarks: {
        globalBenchmarks: null,
        networkBenchmarks: null,
      },
      loading: false,
    }
  },
  computed: {
    indicatorId() {
      return this.indicator ? this.indicator['.key'] : ''
    },
    networkBenchmarks() {
      return (this.benchmarks && this.benchmarks.networkBenchmarks)
        ? this.benchmarks.networkBenchmarks
        : {}
    },
  },
  watch: {
    indicatorId: {
      immediate: true,
      async handler(value) {
        this.showTable = false
        this.loading = true
        try {
          const result = await benchmarks.getByNetwork(this.networkId, value)
          this.benchmarks = result.data
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al recuperar indicadores y/o benchmarks',
              text: err.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.loading = false
        }
      },
    },
  },
  methods: {

  },
}
</script>
